<template>
    <div>
        <div class="hidden md:flex flex justify-between items-center w-full fixed z-10 py-2 containerListing">
            <div class="w-1/24 relative flex justify-center">
                <!-- begin::locales-switcher -->
                <LocaleSwitcher :verticalDrop="true"/>
                <!-- end::locales-switcher -->
            </div>
            <div class="w-1/24 flex justify-center wrapperListing">
                <!-- begin::Menu-icon -->
                <HamburgerMenu @open-hamburguer="openHamburguer" @close-hamburguer="closeHamburguer" color="black"/>
                <!-- end::Main-icon -->
            </div>
        </div>
        <!-- begin::MENU mobile -->
        <div class="lg:hidden">
            <MobileHeader />
        </div>
        <!-- end::MENU mobile -->
        <!-- begin::Page -->
        <router-view v-show="!hamburguerOpen"/>
        <!-- end::Page -->

        <!-- begin::MENU mobile -->
        <div class="lg:hidden">
            <MobileMenu />
        </div>
        <!-- end::MENU mobile -->

    </div>
</template>
<style scoped>
.containerListing{
  padding-top: 0.8rem;
}
.wrapperListing{
  padding-top: 1rem;
}
</style>
<script>
    import HamburgerMenu from "@/components/core/HamburgerMenu";
    import LocaleSwitcher from '@/components/core/LocaleSwitcher'
    import MobileHeader from '@/layout/header/mobile/MobileHeader'
    import MobileMenu from '@/layout/header/mobile/MobileMenu'
    
    export default {
        name: "ListingsTemplate",
        components: { HamburgerMenu, LocaleSwitcher, MobileHeader, MobileMenu},
        data(){
            return{
                hamburguerOpen: false
            }
        },
        methods:{
            openHamburguer(){
                this.hamburguerOpen = true
            },
            closeHamburguer(){
                this.hamburguerOpen = false
            }
        }
        
    }
</script>