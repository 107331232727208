<template>
    <div v-if="showMenu" id="mobile-menu-bottom">
        <div class="mobile-menu-gap">
        </div>
        <div class="w-screen glass-effect glass-effect-white glass-fallback rounded-t-lg flex items-center px-4 py-2 fixed bottom-0 z-50">

            <div class="w-3/12 flex flex-col justify-center object-center items-center">
                <a @click="goTo('home')">
                    <div class="flex  justify-center object-center items-center">
                        <inline-svg width="0.5rem" height="0.8rem" src="/svg/mobileMenu/home_mobile.svg"/>
                    </div>            
                    <p class="text-sm uppercase mt-1">{{ $t('main.mainMenu.home') }}</p>
                </a>
            </div>

            <div class="w-3/12 flex flex-col justify-center object-center items-center">
                <a @click="goToEgo('listings')">
                    <div class="flex flex-col justify-center object-center items-center">
                        <inline-svg width="0.5rem" height="0.8rem" src="/svg/mobileMenu/search_mobile.svg"/>
                    </div>
                    <p class="text-sm uppercase mt-1">{{ $t('main.mainMenu.listings') }}</p>
                </a>
            </div>
            
            <div class="w-3/12 flex flex-col justify-center object-center items-center">
                <a @click="goTo('thisisla')">
                    <div class="flex flex-col justify-center object-center items-center">
                        <inline-svg width="0.5rem" height="0.8rem" src="/svg/mobileMenu/thisisla_mobile.svg"/>
                    </div>
                    <p class="text-sm uppercase mt-1">{{ $t('main.mainMenu.thisisLA') }}</p>
                </a>
            </div>

            <div class="w-3/12 flex flex-col justify-center object-center items-center">
                <a @click="goTo('contact')">
                    <div class="flex flex-col justify-center object-center items-center">
                        <inline-svg width="0.5rem" height="0.8rem" src="/svg/mobileMenu/contact_mobile.svg"/>               
                    </div>
                    <p class="text-sm uppercase mt-1">{{ $t('main.mainMenu.zoom') }}</p>
                </a>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .mobile-menu-gap{
        height:74px;
    }
</style>
<script>
    export default {
        name: "MobileMenu",
        data() {
            return {
                showMenu: true
            }
        },
        methods:{
            goToEgo(p){
                let isEnglish = this.$i18n.locale == 'en'
                if(p == 'listings'){
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties'
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis'
                }
                
                }
            },
            goTo(target){
                switch(target){
                    case 'home':
                        this.$router.push({ name: target, params: { lang: this.$i18n.locale}})
                        break;
                    case 'listings':
                        this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0'}})
                        break;
                    case 'thisisla':
                        this.$router.push({ name: 'ThisIsLA', params: { lang: this.$i18n.locale}})
                        break;
                    case 'contact':
                        this.$router.push({ name: 'home', hash: '#contact_content', params: { lang: this.$i18n.locale}})
                        break;
                    default:
                }
            }
        }
    }
</script>